import React, { Component } from 'react'
import {
  IconNotification,
  IconExport,
  IconUser,
  IconFile,
  IconDown,
  IconLock,
  IconSwap,
} from '@arco-design/web-react/icon'
import {
  Layout,
  PageHeader,
  Button,
  Space,
  Avatar,
  Badge,
  Divider,
  Typography,
  Link,
  Dropdown,
  Menu,
  Message,
  Affix,
  Trigger,
} from '@arco-design/web-react'
import routes from 'router/index'
import history from 'common/utils/history'
import default_avart from 'asserts/default_avater.png'

import './header.scss'
const Header = Layout.Header
const iconStyle = {
  fontSize: 12,
}
const setTitle = (title) => {
  let replaceList = [
    '/myapp/addmyapp',
    '/enginefactory/model/add',
    '/enginefactory/model/management/add',
    '/enginefactory/dataset/add',
  ]
  const urlParams = new URL(window.location.href)
  const pathname = urlParams?.pathname
  if (!replaceList.includes(pathname)) {
    localStorage.setItem('title', title)
  }
}


function setvisibleUpgrade(event) {
  event.stopPropagation()
  history.push('/privilege')
  history.go()
}
function goJump(value) {
  history.push(value)
  window.location.reload()
}
const userinfo = JSON.parse(localStorage.getItem('userinfo'))
function Popup() {
  return (
    <div
      className="demo-trigger-popup"
      style={{ width: 272 }}
      onClick={() => goJump('/authentication')}
    >
      <div className="see-vip">
        <div
          className="AlibabaPuHuiTi-Medium"
          style={{ color: '#936500', fontSize: 16, marginBottom: 2 }}
        >
          开通韦尼克企业尊享版
        </div>
        <div>享受企业会员专属特权，释放AI创作价值</div>
        <button
          className="Upgrade-btn Upgrade-svp AlibabaPuHuiTi-Medium"
          onClick={() => goJump('/authentication')}
        >
          立即升级
        </button>
      </div>
    </div>
  )
}
class Headers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      count: props.count,
      dot: props.dot,
      username: props.username,
      avatar: props.avatar,
      showback: false,
      title: '',
      current: '',
      openKeys: [],
      menuList: routes[0].children,
      extraBreadcrumbItems: null,
      listMapUrl: [],
      pathSnippets: null,
      identity: props.identity,
      has_enterprise: props.has_enterprise,
      vip_level: props.vip_level,
      isopenDown: false,
      istrigger: false,
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      count: nextProps.count,
      dot: nextProps.dot,
      username: nextProps.username,
      avatar: nextProps.avatar,
      identity: nextProps.identity,
      has_enterprise: nextProps.has_enterprise,
      vip_level: nextProps.vip_level,
    })
  }
  UNSAFE_componentDidUpdate(prevProps, prevState) {}
  componentDidMount() {
    document.addEventListener('click', this.HeadersAllMenu)
    this.HeadersAllMenu()
    this.checkHome()
  }
  componentWillUnmount() {
    //在组件卸载及销毁之前直接调用
    document.removeEventListener('click', this.HeadersAllMenu)
  }
  initkey(key) {
    let nowkey = this.sliceURL(key)
    this.state.openKeys = nowkey
    this.state.current = key
    this.setState({
      openKeys: nowkey,
    })
  }
  checkHome(){
        // 获取当前 URL 的参数
  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get('type');
  // 获取 ID 为 layoutHeaders 的元素
  // 检查参数 type 是否等于 'home'
  if (type === 'home') {
    const layoutHeaders = document.getElementById('layoutHeaders');
    if (layoutHeaders) {
      // 获取类名为 arco-page-header-head-main-with-back 的子元素
      const targetElement = layoutHeaders.querySelector('.arco-page-header-head-main');
      if (targetElement) {
        // 设置透明度为 0
        targetElement.style.opacity = '0';
      }
    }
  } else {
    const layoutHeaders = document.getElementById('layoutHeaders');
    if (layoutHeaders) {
      // 获取类名为 arco-page-header-head-main-with-back 的子元素
      const targetElement = layoutHeaders.querySelector('.arco-page-header-head-main');
      
      if (targetElement) {
        // 设置透明度为 0
        targetElement.style.opacity = '1';
      }
    }
  }
  }
  sliceURL(key) {
    let listmap = key.split('/').filter((i) => i)
    let nowkey = listmap.map((_, index) => {
      let url = `/${listmap.slice(0, index + 1).join('/')}`
      return url
    })
    return nowkey
  }
  arrtojson() {
    this.formap(routes[0].children)
    let obj = {}
    this.state.listMapUrl.map((item, index) => {
      obj[item.path] = item.title
      return obj
    })
    return obj
  }
  formap(list) {
    const urlParams = new URL(window.location.href)
    const pathname = urlParams?.pathname
    list.map((pre, item) => {
      if (!pre.children) {
        this.state.listMapUrl.push({ path: pre.path, title: pre.title })
        if (pre.path === pathname) {
          setTitle(pre.title)
        }
      } else {
        this.formap(pre.children)
        if (pre.path === pathname) {
          setTitle(pre.title)
        }
      }
    })
  }
  setTitle() {
    let HistoryList = JSON.parse(localStorage.getItem('HistoryList'))
    let location = history.location
    if (HistoryList && HistoryList.length > 1) {
      if (
        HistoryList[HistoryList.length - 2].url ===
        HistoryList[HistoryList.length - 1].url
      ) {
        if (location.pathname === '/myapp/addmyapp') {
          return HistoryList[HistoryList.length - 2].title
        } else {
          return HistoryList[HistoryList.length - 2].title
        }
      } else {
        return HistoryList[HistoryList.length - 2].title
      }
      // return HistoryList[HistoryList.length - 2].title
    }
  }

  handleSetTitle(val) {
    let str = val
    let appInfo = localStorage.getItem('record')
    if (appInfo) {
      appInfo = JSON.parse(appInfo)
      if (appInfo && appInfo.model_capability === 'textsummarization') {
        str = '文本摘要'
      }
    }
    return str
  }
  HeadersAllMenu = () => {
    this.formap(routes[0].children)
    setTimeout(() => {
      let Menu = JSON.parse(localStorage.getItem('Menu'))
      if (Menu) {
        // 在组件挂载后（插入 DOM 树中）立即调用
        const urlParams = new URL(window.location.href)
        const pathname = urlParams?.pathname
        this.initkey(pathname)
        Menu.openKeys = this.state.openKeys
        Menu.current = this.state.current
        if (Menu.menuList) {
          Menu.menuList = this.state.menuList
        }
        // 防止页面刷新导致菜单栏选中失效
        localStorage.setItem('Menu', JSON.stringify(Menu))
        let len = this.state.openKeys.length
        this.state.pathSnippets = pathname.split('/').filter((i) => i)
        if (pathname === '/') {
          this.setState({
            showback: false,
            title: '',
          })
        } else {
          if (
            this.state.openKeys[len - 1] === '/enginefactory' ||
            this.state.openKeys[0] === '/enginefactory'
          ) {
            if (len > 2) {
              let breadcrumbNameMap = this.setTitle()
              breadcrumbNameMap = this.handleSetTitle(breadcrumbNameMap)
              this.setState({
                showback: true,
                title: breadcrumbNameMap,
              })
            } else {
              this.setState({
                showback: false,
                title: '',
              })
            }
          } else {
            let list = ['/promotion', '/engineservices']

            if (len > 1 || list.includes(pathname)) {
              let breadcrumbNameMap = this.setTitle()
              breadcrumbNameMap = this.handleSetTitle(breadcrumbNameMap)
              this.setState({
                showback: true,
                title: breadcrumbNameMap,
              })
            } else {
              this.setState({
                showback: false,
                title: '',
              })
            }
          }
        }
      }
    }, 100)
  }
  onBack() {
    //console.log("pathname", window.location.pathname);
    window.history.go(-1)
  }
  onVisibleChange(value) {
    this.setState({
      isopenDown: value,
    })
    this.triggerVisible()
  }
  triggerVisible(value) {
    if (this.state.isopenDown) {
      this.setState({
        istrigger: false,
      })
    } else {
      this.setState({
        istrigger: value,
      })
    }
  }
  render() {
    return (
      <Header id="layoutHeaders">
        <PageHeader
          style={{ background: 'transparent' }}
          title={this.state.title}
          subTitle=""
          backIcon={this.state.showback}
          onBack={this.props.onBack.bind(this)}
          extra={
            <div className="headeright dropdown-demo">
              <Space align={'center'}>
                {/* <Link onClick={this.onBack.bind(this)} icon={<IconArrowLeft />}>{this.state.title}</Link> */}
                <Badge text='NEW' offset={[-14,4]}>
                <Link
                  href="/engineservices/billingmethod"
                  onClick={()=>{
                    let appInfo = JSON.parse(localStorage.getItem('record'));
                    appInfo.show_id = ''
                    localStorage.setItem('record', JSON.stringify(appInfo))
                  }}
                  style={{
                    boxShadow: '0px 6px 58px 0px rgba(196,203,214,0.20)',
                    marginRight: 12,
                    padding: '13px 24px',
                    borderRadius: 14,
                  }}
                  className="custom-White-button"
                >
                  <Typography.Text className="end-title">
                    产品价格
                  </Typography.Text>
                </Link>
                </Badge>
                <Link
                  href="/myapp/calldetails?type=home"
                  onClick={()=>{
                    let appInfo = JSON.parse(localStorage.getItem('record'));
                    appInfo.show_id = ''
                    localStorage.setItem('record', JSON.stringify(appInfo))
                  }}
                  icon={
                    <span
                      className="iconfont icon-icon_zhifuxinxi"
                      style={iconStyle}
                    ></span>
                  }
                  style={{
                    boxShadow: '0px 6px 58px 0px rgba(196,203,214,0.10)',
                    marginRight: 12,
                    padding: '13px 18px',
                    borderRadius: 14,
                  }}
                  className="custom-White-button"
                >
                  <Typography.Text className="end-title">
                    调用明细
                  </Typography.Text>
                </Link>
                <Link
                  href="/payment"
                  icon={
                    <span
                      className="iconfont icon-icon_zhifuxinxi"
                      style={iconStyle}
                    ></span>
                  }
                  style={{
                    boxShadow: '0px 6px 58px 0px rgba(196,203,214,0.10)',
                    marginRight: 12,
                    padding: '13px 18px',
                    borderRadius: 14,
                  }}
                  className="custom-White-button"
                >
                  <Typography.Text className="end-title">
                    资源费用
                  </Typography.Text>
                </Link>
                <Link
                  href="/echnicalsupport"
                  icon={<IconFile />}
                  style={{
                    boxShadow: '0px 6px 58px 0px rgba(196,203,214,0.10)',
                    marginRight: 12,
                    padding: '13px 18px',
                    borderRadius: 14,
                  }}
                  className="custom-White-button"
                >
                  <Typography.Text className="end-title">
                    技术支持
                  </Typography.Text>
                </Link>
                <Dropdown
                  triggerProps={{ autoAlignPopupWidth: true }}
                  trigger="click"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  onVisibleChange={this.onVisibleChange.bind(this)}
                  droplist={
                    <Menu className="header-menu">
                      <Menu.Item key="1">
                        <Link
                          href="/personalcenter"
                          hoverable={false}
                          style={{ color: '#0a1629' }}
                        >
                          <IconUser style={iconStyle} />
                          个人中心
                        </Link>
                      </Menu.Item>
                      {/* <Menu.Item
                        style={{
                          display:
                            this.state.identity &&
                            this.state.identity !== 'PERSONAL'
                              ? 'block'
                              : 'none',
                        }}
                        key="2"
                        onClick={this.props.AccessManager.bind(this)}
                      >
                        <Link hoverable={false} style={{ color: '#0a1629' }}>
                          <IconLock style={iconStyle} />
                          访问管理
                        </Link>
                      </Menu.Item> */}
                      {this.state.has_enterprise ? (
                        <Menu.Item
                          key="3"
                          onClick={this.props.switchAccounts.bind(this)}
                        >
                          <Link hoverable={false} style={{ color: '#0a1629' }}>
                            <IconSwap style={iconStyle} />
                            {this.state.identity === 'PERSONAL'
                              ? '切换企业账户'
                              : '切换普通账户'}
                          </Link>
                        </Menu.Item>
                      ) : (
                        ''
                      )}
                      <Menu.Item
                        key="4"
                        onClick={this.props.setoutAuth.bind(this)}
                      >
                        <Link hoverable={false} style={{ color: '#0a1629' }}>
                          <IconExport style={iconStyle} />
                          退出登录
                        </Link>
                      </Menu.Item>
                    </Menu>
                  }
                  position="br"
                >
                  <Button
                    className="custom-select"
                    id="infoselect"
                    style={{ height: 48, padding: '0 13px', borderRadius: 14 }}
                  >
                    <div className="rowCenter">
                      <Avatar
                        size={20}
                        shape="square"
                        style={{ marginRight: 17 }}
                      >
                        {this.state.avatar ? (
                          <img
                            alt="avatar"
                            id="headeravatar"
                            style={{ height: 'auto' }}
                            src={this.state.avatar}
                            onError={(e) => {
                              e.target.src = this.state.avatar
                            }}
                          />
                        ) : (
                          <img
                          alt="avatar"
                          id="headeravatar"
                          style={{ height: 'auto' }}
                          src={default_avart}
                        />
                        )}
                      </Avatar>
                      <Typography.Text ellipsis className="username-ellipsis">
                        {this.state.username || "优小优xxx"}
                      </Typography.Text>
                    </div>
                    {this.state.identity ? (
                      <Trigger
                        popup={() => <Popup />}
                        position="br"
                        popupAlign={{ bottom: 30 }}
                        onVisibleChange={this.triggerVisible.bind(this)}
                        popupVisible={this.state.istrigger}
                        trigger={['hover']}
                        clickToClose={false}
                        classNames="zoomInTop"
                        disabled={
                          !(
                            this.state.identity === 'PERSONAL' ||
                            !this.state.identity
                          )
                        }
                      >
                        {/* <div
                          className={`skew-x ${
                            this.state.identity === 'PERSONAL' ||
                            !this.state.identity
                              ? 'bule'
                              : ''
                          }`}
                        >
                          <div className="AlibabaPuHuiTi-Medium">
                            VIP
                            {this.state.vip_level > 0
                              ? this.state.vip_level
                              : ''}
                          </div>
                        </div> */}
                      </Trigger>
                    ) : (
                      ''
                    )}
                    <IconDown style={{ marginLeft: 20 }} />
                  </Button>
                </Dropdown>
              </Space>
            </div>
          }
        />
      </Header>
    )
  }
}

export default Headers
